"use strict";

import * as UIUtils from "./ui_utils";
import React from "react";
import { Log, LOG_GROUP } from "../server/common/logger/common_log";

const Logger = Log.group(LOG_GROUP.React, "BaseReactComponent");

/**
 * These are React and constructor methods that should be excluded from being bound to the class.
 *
 * Don't add new methods to this class just because you don't want them bound automatically. Modify getAllFunctions
 * instead.
 * @type {Set<string>}
 */
const functionsToExcludeFromAutoBind = new Set([
  "componentDidCatch",
  "componentDidMount",
  "componentDidUpdate",
  "componentWillMount",
  "componentWillReceiveProps",
  "componentWillUnmount",
  "componentWillUpdate",
  "constructor",
  "forceUpdate",
  "getSnapshotBeforeUpdate",
  "render",
  "setState",
  "shouldComponentUpdate",
  "UNSAFE_componentWillMount",
  "UNSAFE_componentWillReceiveProps",
  "UNSAFE_componentWillUpdate",
]);

/**
 * @type {Map<String, Promise<*>>}
 */
const runningPromises = new Map();

/**
 * This is the base class that every React component can use to see if it's mounted or not.
 */
export default class BaseReactComponent extends React.Component {
  constructor(props) {
    super(props);

    this.autoBind();

    this.state = {};
    this._isMounted = false;
  }

  componentDidCatch(error, errorInfo) {
    const componentErrorInfo = {...(errorInfo || {}), fromComponent: true};
    UIUtils.displayCriticalError(error, componentErrorInfo);
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  /**
   * Call this instead of this.setState() when you're not sure if the component is mounted yet.
   *
   * @param someState The state you want to set
   * @param callback A method that should be called after the state is set
   */
  setStateSafely(someState, callback) {
    if (this._isMounted) {
      this.setState(someState, callback);
    } else {
      /**
       * Don't try to condense this to a single line like the below or you'll run into https://github.com/facebook/react/issues/18090
       *
       *    this.state = {...this.state, ...newState}; // Don't do this
       */
      for (const key of Object.keys(someState)) {
        // eslint-disable-next-line react/no-direct-mutation-state
        this.state[key] = someState[key];
      }

      if (callback) {
        callback();
      }
    }
  }

  /**
   * The same as {@link setStateSafely}, but uses a promise instead of a callback.
   * @param someState
   */
  async setStateSafelyAsync(someState) {
    return new Promise((resolve, reject) => {
      try {
        this.setStateSafely(someState, resolve);
      } catch (error) {
        reject(error);
      }
    });
  }

  forceUpdateSafely() {
    if (this._isMounted) {
      this.forceUpdate();
    }
  }

  /**
   * Used by autoBind() to find all functions that need to be bound.
   *
   * @param object {object} the object to find all of the functions for.
   * @return {Set<any>} A set with all of the functions.
   * @private
   */
  getAllFunctions(object) {
    const functions = new Set();

    do {
      for (const key of Reflect.ownKeys(object)) {
        functions.add([object, key]);
      }
    } while ((object = Reflect.getPrototypeOf(object)) && object !== Object.prototype);

    return functions;
  }

  /**
   * Finds all functions and binds them to this object.
   * @private
   */
  autoBind() {
    for (const [object, key] of this.getAllFunctions(this)) {
      if (functionsToExcludeFromAutoBind.has(key)) {
        continue;
      }

      try {
        const descriptor = Reflect.getOwnPropertyDescriptor(object, key);
        if (descriptor && typeof descriptor.value === "function") {
          this[key] = this[key].bind(this);
        }
      } catch (someError) {
        Logger.error(`Cannot auto-bind ${key} because`, Log.error(someError));
      }
    }
  }

  /**
   * Keeps track of UI promises that are being ran at any moment
   * @param promise {Promise<*>|function(): Promise<*>}
   */
  runPromise(promise) {
    if (typeof promise === "function") {
      promise = promise();
    }
    // Uses a UUID to ensure each promise is added only once
    let uuid = (promise && promise.__UUID) || UIUtils.generateUUID();

    let wrappedPromise;
    // If we receive a non-promise result, or a thenable without finally and/or catch,
    // we put it inside a standard promise.
    if (promise && promise.finally && promise.catch) {
      // sets the UUID in the original promise so if we send it again, it won't be added twice
      promise.__UUID = uuid;
      wrappedPromise = promise;
    } else {
      wrappedPromise = Promise.resolve().then(() => promise);
    }
    // If the promise didn't catch the error itself, shows an unexpected error
    wrappedPromise = wrappedPromise
      .finally(() => {
        runningPromises.delete(uuid);
        this.handlePromiseCompleted(runningPromises);
      });
    // stores the UUID in the promise so we can avoid adding it more than once
    wrappedPromise.__UUID = uuid;

    if (!runningPromises.has(uuid)) {
      runningPromises.set(uuid, wrappedPromise);
    }
  }

  /**
   * Override this in child classes if you want to provide custom functionality when a promise completes.
   */
  // eslint-disable-next-line no-unused-vars
  handlePromiseCompleted(runningPromises) {
  }

  /**
   * @returns {boolean} true when the data for this React component is still loading. False otherwise.
   */
  isLoading() {
    // Override this to return true when the class is loading
    return this.state.isLoading || this.props.isLoading;
  }

  /**
   * This provides the CSS classes for showing the skeleton shimmer, when this React component is loading (ie. this.isLoading() === true).
   * NOTE: You probably don't want to override this. Set your special skeleton layout class in `getAdditiveSkeletonClass()`.
   *
   * @param ignoreAdditiveSkeletonClass {boolean} true if the `this.getAdditiveSkeletonClass()` class should not be
   *          included, false otherwise. Default is false.
   * @return {string} the "skeleton" class(es) with a space before it when the data is loading. "" otherwise.
   */
  getClassForLoading(ignoreAdditiveSkeletonClass = false) {
    const additiveSkeletonClass = ignoreAdditiveSkeletonClass ? "" : this.getAdditiveSkeletonClass();
    return this.isLoading() ? " skeleton" + (additiveSkeletonClass ? " " + additiveSkeletonClass : "") : "";
  }

  /**
   * Use this to add a special overlay skeleton class, like when you're covering up a table, etc. These are defined in
   * _headers.scss.
   */
  getAdditiveSkeletonClass() {
    return "";
  }
}
