"use strict";

import * as UIUtils from "../ui_utils";
import React, { Fragment } from "react";
import CompanyLoginHeader from "../widgets/headers/company_login_header";
import UserNewPasswordPopup from "./passwordManagement/user_new_password_popup";
import UserNewSigningPinPopup from "./passwordManagement/user_new_signing_pin_popup";
import { Trans } from "react-i18next";
import * as I18NWrapper from "../i18n/i18n_wrapper";
import FooterBar from "../widgets/bars/footer_bar";
import { cognitoUserPool } from "../helpers/cognito_helper";
import * as AmazonCognitoIdentity from "amazon-cognito-identity-js";
import Cookies from "js-cookie";
import { RetryWrapper, RetryPleaseError } from "../helpers/retry_wrapper";
import ErrorBar from "../widgets/bars/error_bar";
import * as CognitoHelper from "../helpers/cognito_helper";
import CommonSecurity from "../../server/common/generic/common_security";
import CommonUtils from "../../server/common/generic/common_utils";
import CommonURLs from "../../server/common/generic/common_urls";
import BaseReactComponent from "../base_react_component";
import { Log, LOG_GROUP } from "../../server/common/logger/common_log";
import {Button} from "@qbdvision-inc/component-library";

const Logger = Log.group(LOG_GROUP.Users, "UserLogin");

/**
 * This renders the login page.
 */
// i18next-extract-mark-ns-start users
class UserLogin extends BaseReactComponent {
  constructor(props) {
    super(props);

    const {t} = props;
    document.title = t("User login to QbDVision");

    window.addEventListener("popstate", this.handleHistoryChange);

    this.initialize();
  }

  initialize() {
    const {t} = this.props;

    if (UIUtils.getCognitoUUID()) {
      let returnTo = UIUtils.getParameterByName("returnTo");
      if (!returnTo) {
        returnTo = UIUtils.FRONT_END_URL + UIUtils.DEFAULT_RETURN_TO_URL;
      }
      window.location.href = UIUtils.getSecuredURL(returnTo, {
        enforceHostWithinQbDVisionDomain: true
      });
      UIUtils.setLoadingDisabled(false);
      UIUtils.showLoadingImage();
      return;
    }

    this.setStateSafely({
      username: "",
      password: "",
      showNewPasswordPopup: false,
      showNewPinPopup: false,
      cognitoUser: null,
      hasValidEmail: false,
      error: null,
      step: 0,
    });

    const error = UIUtils.getFragmentByName("error_description");
    const code = UIUtils.getFragmentByName("access_token");
    const email = Cookies.get("EMAIL");

    if (error) {

      UIUtils.setHideLoadingOnAjaxStop(false);
      UIUtils.showLoadingImage();
      this.clearHistory();

      UIUtils.secureAjaxGET(`users/userAPI?activity=handleFirstExternalLogin`, {email})
        .done((result) => {

          if (result) {
            if (error.includes("Cognito users with a username/password may not be passed")) {
              this.handleLoginError(t("You seem to have an active session with different email that uses {{ result.identityProvider }}. Please sign out of {{ identityProvider }} and try to login to QbDVision again.", result));
            } else if (error.includes("User does not exist")) {
              const tempPassword = window.atob(result.externalProviderAuth);
              const username = UIUtils.computeUsername(email);

              const authenticationData = {
                Username: username,
                Password: tempPassword
              };

              const userCognitoData = {
                Username: username,
                Pool: cognitoUserPool
              };

              const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
              const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userCognitoData);

              cognitoUser.authenticateUser(authenticationDetails, {
                newPasswordRequired: () => {
                  cognitoUser.completeNewPasswordChallenge(tempPassword, [], {
                    onSuccess: (result) => {
                      UIUtils.setHideLoadingOnAjaxStop(true);
                      UIUtils.hideLoadingImage();
                      this.setState({showNewPinPopup: true, accessInformation: result});
                    }
                  });
                },
                onFailure: (error) => {
                  UIUtils.setHideLoadingOnAjaxStop(true);
                  UIUtils.hideLoadingImage();
                  this.error(error);
                }
              });
            } else if (error) {
              this.error(error);
            }
          } else {
            this.error(t(`{{ email }} is not recognized as part of your configured IDP. Contact your administrator.`, {email}));
          }
        });
    } else if (code) {
      this.clearHistory();

      UIUtils.secureAjaxGET(`users/userAPI?activity=userExists&accessToken=${code}`, {email}, false, (error) => {

        const externalIdentityProvider = Cookies.get("IDENTITY_PROVIDER");
        const errorText = error.responseText;

        if (externalIdentityProvider && errorText === "Your username/password did not match.") {
          sessionStorage["externalLoginError"] = errorText;
          CommonURLs.logoutExternalUser();
        }
      }).done((user) => this.handleExternalUserLogin(user));
    }
  }

  clearHistory(url = window.location.pathname + window.location.search) {
    history.replaceState(UIUtils.cleanStateForHistory(this.state), "", url);
  }

  handleExternalUserLogin(user) {
    const {t} = this.props;

    if (user) {
      const {name, sub, email, accessToken, username} = user;

      if (user.newSigningPinIsRequired) {
        this.setState({
          showNewPinPopup: true,
          accessInformation: {
            encodedAccessToken: accessToken,
            ...user,
          }
        });
      } else {
        UIUtils.showLoadingImage();
        UIUtils.recordSuccessfulLogin({
          encodedAccessToken: accessToken,
        }, {name, sub, email, username});
      }
    } else {
      this.handleLoginError(t("Email/Username is incorrect"));
    }
  }

  handlePasswordModal() {
    if (this.newPasswordPopup) {
      $(this.newPasswordPopup).modal("hide");
    }
    this.setState({
      showNewPasswordPopup: false,
    });
  }

  handlePinModal() {
    if (this.newPinPopup) {
      $(this.newPinPopup).modal("hide");
    }

    this.setState({
      showNewPinPopup: false,
    });
  }

  handleUserLogin(event) {
    event.preventDefault();
    const {username, password} = this.state;
    const {t} = this.props;

    UIUtils.clearError();
    UIUtils.showLoadingImage();
    $.ajax({
      url: UIUtils.getURL(`users/userAPI?activity=getUsername&username=${encodeURIComponent(username)}`),
      type: "GET",
      global: false,
      idempotent: true,
      error: result => {
        let responseJSON = result.responseJSON;
        let error = responseJSON && responseJSON.error ? responseJSON.error : responseJSON;

        if (error && (
          (error.stack && error.stack.startsWith("InvalidParameterException"))
          || (error.code && error.code === "InvalidParameterException")
        )) {
          UIUtils.hideLoadingImage();
          this.handleLoginError(t("Incorrect username or password"));
        } else {
          this.handleLoginError(error);
        }
      }
    }).done((username) => {
      let authenticationData = {
        Username: username,
        Password: password
      };

      let userCognitoData = {
        Username: username,
        Pool: cognitoUserPool
      };

      // noinspection JSIgnoredPromiseFromCall
      new RetryWrapper(() => this.attemptLogin(authenticationData, userCognitoData, username),
        (ignored, waitInMS) => UIUtils.showError(t("Cannot login to QbDVision. Retrying in {{ retryWait }} seconds...", {retryWait: waitInMS / 1000}))
      ).retryFunction();
    });
  }

  /**
   * This is tightly integrated but separated out from the handleUserLogin handler above so that it can be retried.
   */
  async attemptLogin(authenticationData, userCognitoData, username) {
    const {t} = this.props;
    let cognitoUser;
    try {
      Logger.info(() => "Constructing login credentials...");
      const authenticationDetails = new AmazonCognitoIdentity.AuthenticationDetails(authenticationData);
      cognitoUser = new AmazonCognitoIdentity.CognitoUser(userCognitoData);
      await new Promise((resolve, reject) => {
        Logger.info(() => "Attempting to login...");
        cognitoUser.authenticateUser(authenticationDetails, {
          onSuccess: (result) => {
            try {
              Logger.info(() => "Received successful login: " + UIUtils.stringify(result));
              Logger.info(() => "Attributes: " + UIUtils.stringify(result.getIdToken().decodePayload()));
              if (this.newPasswordPopup) {
                $(this.newPasswordPopup).modal("hide");
              }
              UIUtils.recordSuccessfulLogin(result, result.getIdToken().decodePayload());
              resolve();
            } catch (e) {
              Logger.error(() => "Caught error", e);
              reject(e);
            }
          },
          newPasswordRequired: () => {
            // User was signed up by an admin and must provide new
            // password and required attributes, if any, to complete
            // authentication.

            Logger.info(() => "New Password required received.");
            this.setState({showNewPasswordPopup: true, cognitoUser});
            UIUtils.hideLoadingImage();
            resolve();
          },
          onFailure: (error) => {
            reject(error);
          }
        });
      });
    } catch (error) {
      Logger.info(() => "Error received. Maybe retryable:", Log.error(error));
      if (error && error.code === "PasswordResetRequiredException") {
        return new Promise((resolve, reject) => {
          cognitoUser.forgotPassword({
            onSuccess: (data) => {
              // successfully initiated reset password request
              Logger.info(() => "CodeDeliveryData from forgotPassword: " + JSON.stringify(data));
              window.location.href = UIUtils.getSecuredURL(`./users/resetPassword.html?username=${encodeURIComponent(username)}`);
              UIUtils.hideLoadingImage();
              resolve();
            },
            onFailure: (error) => {
              this.handleLoginError(error);
              reject(error);
            }
          });
          UIUtils.hideLoadingImage();
        });
      } else if (CognitoHelper.isCognitoErrorRetryable(error)) {
        Logger.warn(() => "Retrying because of " + UIUtils.stringify(error));
        throw new RetryPleaseError();
      } else {
        if (["NotAuthorizedException", "InvalidParameterException"].includes(error.code)) {
          const errorMessage = t("Incorrect username or password");
          this.handleLoginError(errorMessage);
        } else {
          this.handleLoginError(error);
        }
        UIUtils.hideLoadingImage();
      }
    }
  }

  componentDidMount() {
    super.componentDidMount();
    const {t} = this.props;
    const reason = UIUtils.getParameterByName("reason");
    const externalLoginError = sessionStorage["externalLoginError"];
    let error;

    if (reason === "Terms") {
      error = t("You must accept the terms and conditions to access this site.");
    } else if (reason === "Expired") {
      error = t("Your session timed out.  Please login again.");
    } else if (reason === "UserDisabled") {
      error = t(CommonSecurity.USER_DISABLED_MESSAGE);
    } else if (reason === "CognitoDeveloperError") {
      error = t(CommonSecurity.COGNITO_DEVELOPER_ERROR);
    } else if (externalLoginError) {
      error = externalLoginError;
      sessionStorage["externalLoginError"] = "";
    }

    if (error) {
      this.setStateSafely({error});
    }
  }

  handleChange(event) {
    this.setState({[event.target.name]: event.target.value});
  }

  handleHistoryChange(event) {
    let {state} = event;
    const stepValue = (state && state.step) || UIUtils.getParameterByName("step");
    let step = UIUtils.isInteger(stepValue) ? UIUtils.parseInt(stepValue) : 0;
    let stateChange = null;

    if (step === 0 || !state) {
      UIUtils.showLoadingImage();
      stateChange = {
        error: null,
        step: 0,
        hasValidEmail: false,
        username: "",
        password: "",
      };
      $("#usernameInput").val("");
      $("#passwordInput").val("");
      this.clearHistory(window.location.pathname);
    } else if (step !== this.state.step) {
      UIUtils.showLoadingImage();
      let error = (state && state.error) || null;

      stateChange = {
        ...(state || {}),
        step: step,
        error,
      };
    } else {
      this.initialize();
    }

    if (stateChange) {
      this.setStateSafely(stateChange, () => {
        UIUtils.hideLoadingImage();
      });
    }
  }

  handleLoginError(error) {
    const stepValue = this.state.step;
    let step = UIUtils.isInteger(stepValue) ? UIUtils.parseInt(stepValue) : 0;

    if (typeof error === "string") {
      error = new Error(error);
      error.isValidation = true;
    }

    error.username = this.state.username;
    error.identityProvider = this.state.identityProvider;
    error.hasValidEmail = this.state.hasValidEmail;

    UIUtils.setHideLoadingOnAjaxStop(true);
    UIUtils.hideLoadingImage();

    const stateChange = {step};
    Logger.error(() => "Login error", Log.error(error));
    this.setStateSafely({
      ...stateChange,
      error: error,
    }, () => {
      UIUtils.pushHistoryURLWithParameterChanges(this.state, stateChange, {step: step === 0});
    });
  }

  handleCheckValidEmail(event) {
    event.preventDefault();

    UIUtils.clearError();
    UIUtils.setHideLoadingOnAjaxStop(false);
    UIUtils.showLoadingImage();

    const {t} = this.props;
    const {username} = this.state;

    UIUtils.secureAjaxGET(`users/userAPI?activity=checkIdentityProvider&username=${encodeURIComponent(username)}`,
      null, true, this.handleLoginError).done(user => {
      const {userExists, identityProvider} = user;

      if (!userExists) {
        const error = new Error(t("Email/Username is incorrect"));
        error.doNotSendStackTrace = true;
        error.isValidation = true;
        this.setStateSafely({
          step: 0,
          hasValidEmail: false,
        }, () => this.handleLoginError(error));
      } else if (identityProvider) {
        this.setStateSafely({
          identityProvider,
          error: null,
          hasValidEmail: true,
          step: 3,
        }, () => {
          const historyState = {
            ...this.state,
          };
          delete historyState.password;

          UIUtils.pushHistoryURLWithParameterChanges(historyState, {step: historyState.step});

          Cookies.set("EMAIL", username);
          Cookies.set("IDENTITY_PROVIDER", identityProvider);

          let externalProviderLoginURL = `https://qbdvision-${CommonUtils.SUBDOMAIN}.auth.${CommonUtils.AWS_REGION}.amazoncognito.com`;
          externalProviderLoginURL += `/oauth2/authorize?response_type=token&identity_provider=${identityProvider}&`;
          externalProviderLoginURL += `client_id=${CommonUtils.COGNITO_CLIENT_ID}&`;
          externalProviderLoginURL += `redirect_uri=${CommonUtils.FRONT_END_URL}/&`;
          externalProviderLoginURL += "scope=email+openid+profile+aws.cognito.signin.user.admin";

          window.location.href = UIUtils.getSecuredURL(externalProviderLoginURL);
        });
      } else {
        Cookies.remove("IDENTITY_PROVIDER", identityProvider);

        this.setStateSafely({
          hasValidEmail: true,
          error: null,
          step: 2,
        }, () => {
          const historyState = {
            ...this.state,
          };
          delete historyState.password;

          UIUtils.pushHistoryURLWithParameterChanges(historyState, {step: historyState.step});
          UIUtils.setHideLoadingOnAjaxStop(true);
          UIUtils.hideLoadingImage();
        });
      }
    }
    ).fail(
      (error) => {
        this.setStateSafely({
          hasValidEmail: false,
          step: 0,
        }, () => this.handleLoginError(error));
      }
    );
  }

  render() {
    const {t} = this.props;
    const {
      hasValidEmail,
      accessInformation,
      showNewPasswordPopup,
      showNewPinPopup,
      cognitoUser,
      step,
      error,
    } = this.state;

    if (error) {
      this.showErrorMessage(error);
    } else {
      UIUtils.clearError();
    }

    const showPasswordField = step === 2;
    const showCreateCompanyButton = !CommonUtils.isCommercialEnvironment();

    return (
      <div>
        <div className="container-fluid">
          <CompanyLoginHeader firstHeader={
            <Trans t={t}>
              <span id="pageTitleBar">Welcome back to
                <span className="qbd"> QbD</span>
                <span className="vision">Vision</span>
                <sup>&reg;</sup>
              </span>
            </Trans>
          }
          />
          <br />
          <div className="center-single-column-grid">
            <div className="row">
              <div className="col-sm-12">
                <form data-toggle="validator" role="form" id="loginForm" onSubmit={this.handleCheckValidEmail}>
                  <ErrorBar className={"error-bar login-error-bar"} />
                  <div className="form-group">
                    <input type="text"
                           className="form-control"
                           placeholder={t("Email / Username")}
                           name="username"
                           id="usernameInput"
                           data-required-error={t("An email or username is required")}
                           autoComplete="username"
                           required={true}
                           disabled={showPasswordField}
                           onChange={this.handleChange}
                    />
                    <div className="help-block with-errors" />
                  </div>
                  <Fragment>
                    {/*
                    For password helpers just as LastPass to work fine,
                    the password field must be present in the DOM, but hidden.
                    */}
                    <div className={`form-group ${showPasswordField ? "" : "d-none"}`}>
                      <input type="password"
                             placeholder={t("Password")}
                             className="form-control"
                             name="password"
                             id="passwordInput"
                             autoComplete="current-password"
                             data-minlength={8}
                             data-error={t("Password is incorrect. See password requirements above.")}
                             required={showPasswordField}
                             onChange={this.handleChange}
                      />
                      <div className="help-block with-errors" />
                    </div>
                    <div className={`form-group ${showPasswordField ? "" : "d-none"}`}>
                      <a id="forgotPasswordLink" href="./users/forgotPassword.html">{t("Forgot Password")}</a>
                    </div>
                  </Fragment>
                  <br />
                  {hasValidEmail ?
                    <div className="form-group">
                      <Button onClick={this.handleUserLogin}
                              id="loginButton"
                              label={t("Login")}
                              isFullWidth />
                    </div> :
                    <div className="form-group">
                      <Button id={"nextButton"}
                              label={t("Next")}
                              isSubmit
                              isFullWidth />
                    </div>}
                  {showCreateCompanyButton ?
                    <div id="createCompanyButton" className="form-group">
                      <p className="text-center loginPageSeparator-text">{t("or")}</p>
                      <p className="text-center loginPageLinks-text">
                        <a href="./users/createCompany.html">{t("Create Company")}</a>
                      </p>
                    </div> : ""}
                </form>
              </div>
            </div>
          </div>
        </div>
        {showNewPasswordPopup ?
          <UserNewPasswordPopup modalRef={newPasswordPopup => this.newPasswordPopup = newPasswordPopup}
                                onHideModal={this.handlePasswordModal}
                                cognitoUser={cognitoUser}
                                id="userNewPasswordPopup"
          /> : ""}
        {showNewPinPopup ?
          <UserNewSigningPinPopup modalRef={newPinPopup => this.newPinPopup = newPinPopup}
                                  onHideModal={this.handlePinModal}
                                  accessInformation={accessInformation}
                                  id="UserNewSigningPinPopup"
          /> : ""}
        <div className="footer-login">
          <FooterBar />
        </div>
      </div>
    );
  }

  showErrorMessage(error) {
    if (typeof error === "string") {
      error = new Error(error);
      error.isValidation = true;
    }

    const {username} = this.state;

    error.username = username;
    error.cognitoUser = username;
    UIUtils.defaultFailFunction(error);
  }
}

const UserLoginI18n = I18NWrapper.wrap(UserLogin, "users");
UIUtils.render("loginPage", <UserLoginI18n />);
// i18next-extract-mark-ns-stop users
