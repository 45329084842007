"use strict";

const isLambda = !!(process.env.LAMBDA_TASK_ROOT || false);

class CommonString {
  static isValidString(value) {
    return typeof value === "string" && value.toString().trim() !== "";
  }

  static isFalsyOrWhiteSpaceString(value) {
    return !value || typeof (value) !== "string" || value.trim().length === 0;
  }

  /**
   * Encodes any string into single byte characters. All string characters are saved in a Uint32Array, which allows up
   * to 4 bytes per character. This adds support for all characters from the Basic Multilingual Plane (BMP) unicode
   * standard, but also any supplementary characters, making it up for the full UTF8 character range. The encoding we
   * use in the database is utf8mb4, which supports the full range of UTF8 character set, so it is essential that the
   * full range of UTF8 characters are encoded correctly when transmitting user data over web-sockets to the backend.
   * Read more: https://encoding.spec.whatwg.org/#interface-textencoder
   *
   * @param string {string} The regular UTF-8 string to convert
   * @returns {string} The string in base64.
   */
  static stringToBase64(string) {
    const binaryArray = new TextEncoder().encode(string);

    let returnVal;
    if (isLambda) {
      returnVal = Buffer.from(binaryArray, "binary").toString("base64");
    } else {
      // Convert the binary Uint8Array to a String.
      let binaryString = "";
      for (let i = 0; i < binaryArray.byteLength; i++) {
        binaryString += String.fromCharCode(binaryArray[i]);
      }

      returnVal = btoa(binaryString);
    }

    return returnVal;
  }

  /**
   * Creates a hash from a given string
   * https://stackoverflow.com/questions/7616461/generate-a-hash-from-string-in-javascript
   * @param str
   * @param seed
   * @returns {number}
   */
  static cyrb53(str, seed = 0) {
    let h1 = 0xdeadbeef ^ seed,
      h2 = 0x41c6ce57 ^ seed;
    for (let i = 0, ch; i < str.length; i++) {
      ch = str.charCodeAt(i);
      h1 = Math.imul(h1 ^ ch, 2654435761);
      h2 = Math.imul(h2 ^ ch, 1597334677);
    }

    h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
    h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);

    return 4294967296 * (2097151 & h2) + (h1 >>> 0);
  }


  /**
   * Like stringToBase64, except this method assumes that the input is not UTF-8 but instead latin-1, which is what is
   * output from fflate. Using this method with fflate can reduce the size of the outputted base64 significantly.
   *
   * @param string {string} The latin-1 encoded string to convert
   * @returns {string} The string in base64.
   */
  static latin1ToBase64(string) {
    let returnVal;
    if (isLambda) {
      returnVal = Buffer.from(string, "latin1").toString("base64");
    } else {
      returnVal = btoa(string);
    }
    return returnVal;
  }

  /**
   * This does the reverse operation of stringToBinary, taking a stream of 1 byte characters and converting them to
   * a CharCodes from 4 byte characters.  Read more: https://encoding.spec.whatwg.org/#interface-textdecoder
   *
   * @param base64String {string} The string in base64.
   * @returns {string} The original string (in base64 encoding).
   */
  static base64ToString(base64String) {
    let binaryString = isLambda
      ? Buffer.from(base64String, "base64").toString("binary")
      : atob(base64String);

    const binaryArray = Uint8Array.from(binaryString, c => c.charCodeAt(0));
    return new TextDecoder().decode(binaryArray);
  }

  /**
   * Like base64ToString, except this method assumes that the input is not UTF-8 but instead latin-1, which is what is
   * output from fflate. Using this method with fflate can reduce the size of the outputted base64 significantly.
   *
   * @param base64String {string} The string in base64.
   * @returns {string} The original string (in latin-1 encoding).
   */
  static base64ToLatin1(base64String) {
    let returnVal;
    if (isLambda) {
      returnVal = Buffer.from(base64String, "base64").toString("latin1");
    } else {
      returnVal = atob(base64String);
    }
    return returnVal;
  }

  /**
   * Make sure that a given string isn't too long. If it is, snip it off and add "..." at the end.
   *
   * @param string The string to make sure it's not too long.
   * @param maxLength The number of characters to allow.
   * @return {string|*} The original string, possibly snipped with "..." at the end if it's too long.
   */
  static forceMaxLength(string, maxLength) {
    return string && string.length > maxLength ? string.substring(0, maxLength - 3) + "..." : string;
  }

  /**
   * Escapes a string to be used as a {@link RegExp}.
   * @param expression {string}
   */
  static escapeForRegExp(expression) {
    return expression.replace(/[-[\]{}()*+!<=:?./\\^$|#\s,]/g, "\\$&");
  }
}

module.exports = {
  CommonString,
};
