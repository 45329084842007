"use strict";

import * as CommonUtils from "../../../server/common/generic/common_utils";
import { ExportResultCode } from "@opentelemetry/core";
import Cookies from "js-cookie";

/**
 * @extends {SpanExporter}
 */
export class QbdVisionClientExporter {
  constructor(options) {
    /**
     * @type {SpanExporter[]}
     */
    this.options = options;

    /**
     * @type {string}
     */
    this.url = CommonUtils.API_URL + "telemetry";
  }

  /**
   * @inheritDoc
   */
  async export(spans, resultCallback) {
    const spansToExport = spans.filter(
      span => span.name === "error" || (span.duration && span.duration[1] > 5000000)
    ).map(span => ({
      name: span.name,
      parentSpanId: span.parentSpanId,
      serviceName: this.options.serviceName,
      attributes: {
        serviceName: this.options.serviceName,
        ...(span.attributes || {}),
        ...((span.resource && span.resource.attributes) || {}),
      },
      links: span.links,
      events: span.events,
      status: span.status,
      startTime: span.startTime,
      endTime: span.endTime,
      ended: span.ended,
      duration: span.duration,
      kind: span.kind,
      resource: span.resource,
      instrumentationLibrary: span.instrumentationLibrary,
    }));

    const reportSuccess = () => resultCallback({code: ExportResultCode.SUCCESS});

    const logError = (error) => {
      resultCallback({code: ExportResultCode.FAILED, error});
    };

    try {
      // Tries to send the message using the browser beacon API. If it fails, uses the XHR API.
      if (!this.sendWithBeacon(spansToExport)) {
        await this.sendWithXHR(spansToExport);
      }
    } catch (e) {
      logError(e);
    } finally {
      // We don't want more errors appearing if we fail to send telemetry data.
      reportSuccess();
    }
  }

  async sendWithXHR(spansToExport) {
    let result = false;
    // does not use AjaxWrapper so we don't end up with an infinite stack of errors if telemetry fails
    let xhr = new XMLHttpRequest();
    xhr.open("POST", this.url, false);
    xhr.setRequestHeader("Content-Type", "application/json");
    xhr.setRequestHeader("Authorization", Cookies.get("ACCESS_TOKEN"));
    xhr.send(JSON.stringify(spansToExport));

    if (xhr.status >= 200 && xhr.status < 300) {
      result = true;
    } else {
      const error = new Error(`Unable to send telemetry data. Response: ${xhr.status} - ${xhr.statusText}`);
      error.name = "TelemetryExportFailedError";
      error.request = xhr;
      throw error;
    }
    return result;
  }

  sendWithBeacon(spansToExport) {
    let result = false;
    if (typeof navigator !== "undefined" && typeof navigator.sendBeacon !== "undefined") {
      result = navigator.sendBeacon(this.url, JSON.stringify(spansToExport));
    }
    return result;
  }

  /**
   * @inheritDoc
   */
  async shutdown() {

  }
}
