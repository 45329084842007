"use strict";

const {CommonString} = require("./common_string");

const {Log, LOG_GROUP} = require("../logger/common_log");

const Logger = Log.group(LOG_GROUP.Framework, "Ensure");

/**
 * @typedef IValidationEntry
 * @property {*} value The value to be validated
 * @property {string} name The name of the argument referring to that.
 * @property {string} [displayName] The name to be displayed when referring to the argument.
 * @property {boolean} [isValid] The validation result
 */

/**
 * Executes the specified validation logic on all items of the array.
 * @callback ValidationOperator
 * @param values {*[]} The values to be checked
 * @param validation {function(*[]): boolean} The validation that will be executed on each value.
 * @return {boolean}
 */

class Ensure {
  /**
   * Executes the specified validation logic on all items of the array using && to combine the result.
   * @param values {*[]} The values to be checked
   * @param validation {function(*[]): boolean} The validation that will be executed on each value.
   * @return {boolean}
   */
  static AND(values, validation) {
    return values.reduce((aggregate, item) => {
      const result = validation(item);
      if (!result) {
        item.isValid = false;
      }
      return aggregate & result;
    }, true);
  }

  /**
   * Executes the specified validation logic on all items of the array using || to combine the result.
   * @param values {*[]} The values to be checked
   * @param validation {function(*[]): boolean} The validation that will be executed on each value.
   * @return {boolean}
   */
  static OR(values, validation) {
    return values.reduce((aggregate, item) => {
      const result = validation(item);
      if (!result) {
        item.isValid = false;
      }
      return aggregate | result;

    }, false);
  }

  /**
   * Creates a validator for input parameters
   * @param entries {object} The value to be validated (a plain object with one key/value for each parameter)
   * @return {Ensure}
   */
  static that(entries) {
    const instance = new Ensure();

    let entriesToAdd = this.getValidationEntriesFromPlainObjectArray(entries);

    for (let validationEntry of entriesToAdd) {
      instance.addValidationEntry(validationEntry);
    }

    return instance;
  }

  /**
   * Creates validation entries out of the values
   * @param validationEntry
   * @return {IValidationEntry[]}
   * @private
   */
  static getValidationEntriesFromPlainObjectArray(validationEntry) {
    return Object.entries(validationEntry).map(([name, value]) => {
      return {name, value};
    });
  }

  /**
   * Creates a validator for input parameters
   * @return {Ensure}
   */
  constructor() {
    this.areOfType = this.areOfType.bind(this);
    this.isOfType = this.isOfType.bind(this);
    this.isNotFalsyNorWhiteSpaceString = this.isNotFalsyNorWhiteSpaceString.bind(this);
    this.areNotFalsyNorWhiteSpaceString = this.areNotFalsyNorWhiteSpaceString.bind(this);
    this.isNotFalsy = this.isNotFalsy.bind(this);
    this.isTruthy = this.isNotFalsy.bind(this);

    /**
     * @type {IValidationEntry[]}
     * @private
     */
    this._entries = [];
  }

  /**
   * Adds a new item to the validation list
   * @param validationEntry {IValidationEntry} The new entry to be added.
   * @private
   */
  addValidationEntry(validationEntry) {
    if (CommonString.isFalsyOrWhiteSpaceString(validationEntry.name)) {
      validationEntry.name = "";
    }

    this._entries.push(validationEntry);
  }

  /**
   * Ensures that all items in the current array are instances of the specified type.
   * @param type {string|typeof}
   * @param typeDisplayName {string} The display name of the type of the evaluated argument in the error message.
   * @param [message] {string} The message to be displayed in case of error.
   * @param [operator] {ValidationOperator} The operator that will perform the combination of the results for each item.
   * @return {Ensure}
   */
  areOfType(type, {typeDisplayName = type, message = null, operator = Ensure.AND} = {}) {
    let success = operator(this._entries, (item) => {
      if (typeof type === "string") {
        return typeof item.value === type;
      } else {
        return item.value instanceof type;
      }
    });

    if (!success) {
      this.fail(operator, message, `must be an instance of the type "${typeDisplayName || type}"`);
    }
    return this;
  }

  /**
   * Ensures that all items in the current array are instances of the specified type.
   * @param types {*|string[]}
   * @param [message] {string} The message to be displayed in case of error.
   * @param [operator] {ValidationOperator} The operator that will perform the combination of the results for each item.
   * @return {Ensure}
   */
  areOfOneOfTheseTypes(types, {message = null, operator = Ensure.AND} = {}) {
    const displayTypes = [];

    let success = operator(this._entries, (item) => {
      let typesToCheck;
      if (typeof types === "string") {
        typesToCheck = [[types, types]];
      } else if (Array.isArray(types)) {
        typesToCheck = types;
      } else {
        typesToCheck = Object.entries(types);
      }
      return Ensure.OR(typesToCheck, ([typeKey, type]) => {
        displayTypes.push(typeKey);
        if (typeof type === "string") {
          return typeof item.value === type;
        } else {
          return item.value instanceof type;
        }
      });
    });

    if (!success) {
      this.fail(operator, message, `must be an instance of one of the following types: ${displayTypes.join(", ")}`);
    }
    return this;
  }

  /**
   * Ensures that items in the current string array are not falsy nor whitespaces
   * operator {ValidationOperator} The operator that will perform the combination of the results for each item.
   * @param {?string} [message] The message to be displayed in case of error.
   * @param {Function} operator
   * @return {Ensure}
   */
  areNotFalsyNorWhiteSpaceString({operator = Ensure.AND, message = null} = {}) {

    let success = operator(this._entries, item => !CommonString.isFalsyOrWhiteSpaceString(item.value));

    if (!success) {
      this.fail(operator, message, `must be a truthy, non-empty string that contains at least one non-whitespace character`);
    }
    return this;
  }

  /**
   * Ensures that values do not have only falsy items
   * operator {ValidationOperator} The operator that will perform the combination of the results for each item.
   * @param [message] {string} The message to be displayed in case of error.
   * @param {Function} operator
   * @return {Ensure}
   */
  areNotFalsy({message = null, operator = Ensure.AND} = {}) {
    let success = operator(this._entries, item => !!item.value);

    if (!success) {
      this.fail(operator, message, `must be a truthy object`);
    }
    return this;
  }

  /**
   * Ensures the current value is of the specified type
   * @param type {*}
   * @param [message] {string} The message to be displayed in case of error.
   */
  isOfType(type, {message = null} = {}) {
    return this.areOfOneOfTheseTypes(type, {message});
  }

  /**
   * Ensures the current string is not falsy nor composed only of whitespaces
   * @param [message] {string} The message to be displayed in case of error.
   */
  isNotFalsyNorWhiteSpaceString(message = null) {
    return this.areNotFalsyNorWhiteSpaceString({message});
  }

  /**
   * Ensures the current value is not falsy
   * @param [message] {string} The message to be displayed in case of error.
   */
  isNotFalsy(message = null) {
    return this.areNotFalsy({message});
  }

  /**
   * Ensures the current value is of the specified type
   * @param types {*}
   * @param typeDisplayName {string} The display name of the type of the evaluated argument in the error message.
   * @param [message] {string} The message to be displayed in case of error.
   */
  isOfOneOfTheseTypes(types, {message = null} = {}) {
    return this.areOfOneOfTheseTypes(types, {message});
  }

  /**
   * Throws an error with the information about the argument being evaluated.
   * @param operator {Function} The operator used for the validation
   * @param customMessage {?string} The main information to be included in the customMessage.
   * @param defaultMessage {string} The main information to be included in the customMessage.
   * @return {string}
   * @private
   */
  fail(operator, customMessage, defaultMessage) {
    let displayNames = this._entries
      .filter(entry => !CommonString.isFalsyOrWhiteSpaceString(entry.name))
      .map(entry => `"${entry.name}"`).join(", ");

    if (!CommonString.isFalsyOrWhiteSpaceString(displayNames)) {
      displayNames += " ";
    }

    let errorMessage = customMessage;

    if (CommonString.isFalsyOrWhiteSpaceString(errorMessage)) {
      errorMessage = "Invalid arguments: ";
      if (this._entries.length > 1) {
        const quantifier = this.getMessageQuantifier(operator);
        errorMessage += `${quantifier} the values specified for the arguments ${displayNames}${defaultMessage}.`;
      } else {
        errorMessage += `The value specified for the argument ${displayNames}${defaultMessage}.`;
      }
    }

    let additionalDetails = `Input arguments:`;

    if (this._entries.length) {
      for (let {displayName, name, value} of this._entries) {
        try {
          additionalDetails += `\n- ${displayName || name}: \n${JSON.stringify(value, null, 2)}\n`;
        } catch (error) {
          if (typeof value === "object") {
            additionalDetails += `\n- ${displayName || name}: \n{\n  ${Object.entries(value).map(([key, innerValue]) => `${key}: ${innerValue}`).join(",\n  ")}\n}`;
          } else {
            additionalDetails += `\n- ${displayName || name}: ${value}`;
          }
        }
      }
    }

    const error = new Error(errorMessage);
    error.name = "InvalidArgumentError";
    Error.captureStackTrace(error);
    error.stack = additionalDetails + "\n\n" + error.stack;
    throw error;
  }

  /**
   * Returns the beginning of the validation sentence, based on the operator.
   * @param operator {ValidationOperator}
   * @return {string}
   * @private
   */
  getMessageQuantifier(operator) {
    if (operator === Ensure.AND) {
      return "All";
    } else {
      return "At least one of";
    }
  }

  /**
   * Throws an error indicating that the method is not implemented.
   * @param methodName {string} The name of the method.
   * @param [args] {*} An object whose keys determine the arguments for the non-implemented method
   * (will appear on the error message, and also would help avoid unused variables issues in eslint).
   */
  static notImplemented(methodName = "", args = {}) {
    // TODO: Merge ServerSideImplementationNeeded and ImplementationNeededError into the same class and call here
    const argList = [...Object.keys(args)];

    const message = `Method not implemented: ${methodName}(${argList.join(", ")})`;
    throw new Error(message);
  }

  /**
   * Makes a placeholder of the current method with no implementation and returning the specified value
   * so that eslint doesn't complain about the method having unused parameters or doing nothing.
   * @param methodName {string} The name of the method.
   * @param [args] {*} An object whose keys determine the arguments for the non-implemented method
   * (would help avoid unused variables issues in eslint)
   * @param [returnValue] {T} The return value for this method (leave blank for void)
   * @returns T
   * @template {*} T
   */
  static virtual(methodName, args = {}, returnValue = undefined) {
    const argList = [...Object.keys(args)];

    Logger.verbose(() => `Virtual method ${Log.symbol(methodName)} invoked with arguments ${Log.symbol(argList.join(", "))}`);

    if (typeof returnValue !== "undefined") {
      return returnValue;
    }
  }
}

Ensure.TYPES = {
  FUNCTION: {"function": "function"},
  STRING: {"string": "string"},
  BOOLEAN: {"boolean": "boolean"},
  NUMBER: {"number": "number"},
  OBJECT: {"object": "object"},
};

module.exports = {
  Ensure,
};
