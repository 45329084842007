"use strict";

import React, { Fragment } from "react";
import moment from "moment";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as I18NWrapper from "../../i18n/i18n_wrapper";
import CommonUtils from "../../../server/common/generic/common_utils";

const shouldShowLinkToSystemStatus = (
  !CommonUtils.isProductionOrStaging()
  && !CommonUtils.isEnvironmentPublicToClients()
);


// i18next-extract-mark-ns-start widgets
class FooterBar extends React.Component {
  render() {
    const {t} = this.props;
    return (
      <div className="footer container">
        <div className="footer-copyright" id="footerCopyright">
          &copy; 2017-{moment().format("YYYY")}
          <a href="https://www.qbdvision.com" rel="noopener noreferrer" target="_blank">QbDVision, Inc.</a>
        </div>
        <div className="footer-separator">●</div>
        <div className="footer-links">
          <a id="footerPrivacyPolicy" href="/terms/privacyPolicy.html" target="_blank">{t("Privacy Policy")}
            <span className="footer-link-icon">
              <FontAwesomeIcon id="footerPrivacyPolicy"
                               icon={faExternalLinkAlt}
              />
            </span>
          </a>
        </div>
        {shouldShowLinkToSystemStatus ? (
          <Fragment>
            <div className="footer-separator">●</div>
            <div className="footer-links">
              <a id="footerCheckSystem" href="/sysChecks/check.html">{t("System Status")}</a>
            </div>
          </Fragment>
        ) : ""}
      </div>
    );
  }
}

export default I18NWrapper.wrap(FooterBar, "widgets");
// i18next-extract-mark-ns-stop widgets
