"use strict";

import React from "react";
import QbDVisionLogo from "../../images/logo.svg";

export default class CompanyLoginHeader extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div id="userCompanyLoginHeader">
        <div className="row justify-content-center login-jumbotron">
          <a href="../../index.html">
            <img src={QbDVisionLogo}
                 width={256} height={256}
                 className="mx-auto d-block"
                 alt="QbDVision Logo"
            />
          </a>
        </div>

        <h1 className="text-center">{this.props.firstHeader}</h1>
        {this.props.paragraph ? (this.props.isParagraphHtml ? this.props.paragraph :
          <p className="text-center">{this.props.paragraph}</p>) : ""}
      </div>
    );
  }
}
