"use strict";

import * as CommonUtils from "../../server/common/generic/common_utils";
import {CognitoUserPool} from "amazon-cognito-identity-js";

/**
 * The functions in this file are responsible for providing Cognito helper methods
 */

export const COGNITO_USER_POOL_DATA = {
  UserPoolId: CommonUtils.COGNITO_POOL_ID,
  ClientId: CommonUtils.COGNITO_CLIENT_ID
};

export const cognitoUserPool = new CognitoUserPool(COGNITO_USER_POOL_DATA);

export function isCognitoErrorRetryable(err) {
  return err && ((err.statusText === "error" && err.status === 504)
    || (err.status === 500)
    || (err.code === "InternalErrorException")
    || (err.code === "NetworkError")
    || (err.code === "UnknownError"));
}

