"use strict";

import * as UIUtils from "../../ui_utils";
import ImplementationNeededError from "../implementation_needed_error";
import Promisable from "./promisable";

export const LOADING_PLEASE_WAIT = "Loading... please wait.";

/**
 * This is the base class for caches that keep various typeahead options and objects.
 *
 * NOTE: Many of the internal methods here take a type as an argument even though there's a this.type. That's because
 * the MultipleTypeaheadObjectCache needs to handle multiple types and calls them repeatedly with different types.
 *
 * @override
 */
export default class BaseObjectCache {
  /**
   * @param type {string} The type that will be loaded.
   */
  constructor(type) {
    this.type = type;

    // Bind the results method because it loses the this context when called from an Ajax response.
    this.handleTypeaheadResultsFromServer = this.handleTypeaheadResultsFromServer.bind(this);
    this.defaultFailFunction = this.defaultFailFunction.bind(this);
  }

  static isObjectCacheStillLoading(options) {
    return !options || options.isLoading;
  }

  static getInitialOptions() {
    let options = [LOADING_PLEASE_WAIT];
    options.isLoading = true;
    return options;
  }

  /**
   * @return {string} The name of the sessionStorage variable where this cache will be stored.
   */
  getCacheName() {
    throw new ImplementationNeededError();
  }

  /**
   * This should return an ID that represents whatever IDs the subtype constructor was initialized with.
   *
   * @return {number|string} an ID of some kind to represent the value or set of values cached.
   * @abstract
   * @protected
   */
  getId() {
    throw new ImplementationNeededError();
  }

  /**
   * @return {object} An object where the keys are ids (see getId()) and the values are functions that need to be called
   * once the data is loaded.
   * @abstract
   * @protected
   */
  // eslint-disable-next-line
  getIdToOnLoadedFunctions(type) {
    throw new ImplementationNeededError();
  }

  /**
   * @return {string} A URL to the server to get the information to be cached.
   * @abstract
   * @protected
   */
  buildURLForAjaxGet() {
    throw new ImplementationNeededError();
  }

  /**
   * Optionally add extra ajax parameters to the request for the data to cache.
   *
   * @protected
   */
  // eslint-disable-next-line no-unused-vars
  addAjaxParameters(ajaxRequestData) {
  }

  /**
   * Clear out the cache.
   */
  clearIdToOnLoadedFunctions() {
    throw new ImplementationNeededError();
  }

  /**
   * Call this method when the page loads to go load your typeaheadType.  Once everything is loaded, it'll call the
   * onLoaded method that you've passed in. If the options were previously loaded on this page, the onLoadedFunction will
   * be called immediately. Once your onLoaded method has been called, calling getOptionsFromCache will immediately return your data.
   *
   * @param [onLoadedFunction] {function} The callback function to invoke when objects are loaded from the backend
   * @param [requestData] {object} Extra QueryString parameters that will be passed in the Ajax call for loading the object cache options.
   * @param [requestData.includeAllVersions] {boolean} True if you want all versions included in the typeahead, false otherwise.
   * @param [requestData.includeAllApprovedVersions] {boolean} True if you want all approved versions included in the typeahead, false otherwise.
   * @param throwOnError {boolean} If true, the returned promise will throw an error. By default, it handles the errors automatically.
   * @return {Promisable} An object that can provide a promise that is complete once the data for all of the data has
   * been loaded. The first argument will be the results.
   */
  loadOptions(onLoadedFunction, requestData, {throwOnError = false} = {throwOnError: false}) {
    let returnPromise;
    const id = this.getId();

    let options = this.getOptionsFromCache();
    let notAlreadyLoaded = !options || BaseObjectCache.isObjectCacheStillLoading(options);
    // Ensure the versions have been loaded
    if (!notAlreadyLoaded && requestData?.includeAllVersions) {
      notAlreadyLoaded = !options[Object.keys(options)[0]]?.allVersionsWithDetails;
    }
    if (!notAlreadyLoaded && requestData?.includeAllApprovedVersions) {
      notAlreadyLoaded = !options[Object.keys(options)[0]]?.approvedVersionsWithDetails;
    }

    if (notAlreadyLoaded) {
      let idToOnLoadedFunctions = this.getIdToOnLoadedFunctions();
      const hasNotStartedLoading = !idToOnLoadedFunctions || !idToOnLoadedFunctions[id];
      if (hasNotStartedLoading) {
        this.addOnLoadedFunction(onLoadedFunction);
        let url = this.buildURLForAjaxGet();

        let ajaxRequestData = {
          approved: false,
          isCacheRequest: true,
          ...requestData,
        };
        this.addAjaxParameters(ajaxRequestData);

        returnPromise = new Promise(resolve => {
          UIUtils.secureAjaxGET(url, ajaxRequestData, true, this.defaultFailFunction, false)
            .done(result => {
              this.handleTypeaheadResultsFromServer(result);
              resolve(result);
            });
        });
      } else {
        if (options && !BaseObjectCache.isObjectCacheStillLoading(options)) {
          // The options have already been loaded on this page before this method was called
          onLoadedFunction && onLoadedFunction(options);
          returnPromise = Promise.resolve(options);
        } else {
          returnPromise = new Promise(resolve => {
            this.addOnLoadedFunction(results => {
              onLoadedFunction && onLoadedFunction(results);
              resolve(results);
            });
          });
        }
      }
    } else if (onLoadedFunction) {
      // The options have already been loaded, possibly on a different page.
      onLoadedFunction(options);
      returnPromise = Promise.resolve(options);
    } else {
      returnPromise = Promise.resolve(options);
    }

    // Makes sure errors are not ignored
    if (returnPromise && !throwOnError) {
      returnPromise = returnPromise.catch(error => UIUtils.defaultFailFunction(error));
    }
    return new Promisable(returnPromise);
  }

  /**
   * Add a function to be called later when the data is loaded.
   *
   * @return {object} the idToOnLoadedFunctions that was modified.
   *
   * @protected
   */
  // eslint-disable-next-line no-unused-vars
  addOnLoadedFunction(onLoadedFunction, id = this.getId(), type = this.type) {
    let idToOnLoadedFunctions = this.getIdToOnLoadedFunctions(type);

    if (!idToOnLoadedFunctions[id]) {
      idToOnLoadedFunctions[id] = [];
    }

    if (onLoadedFunction) {
      idToOnLoadedFunctions[id].push(onLoadedFunction);
    }

    return idToOnLoadedFunctions;
  }

  defaultFailFunction(result) {
    if (result && result.responseJSON && result.responseJSON.code === 403) {
      this.invalidateCacheOptions();
    }
    UIUtils.defaultFailFunction(result);
  }

  handleTypeaheadResultsFromServer(result) {
    const id = this.getId();
    const typeCode = UIUtils.getTypeCodeForModelName(this.type);

    this.setCacheOptions(result);
    const idToonLoadedFunctions = this.getIdToOnLoadedFunctions();
    const onLoadedFunctions = (idToonLoadedFunctions && idToonLoadedFunctions[id]) || [];
    for (let onLoadedFunction of onLoadedFunctions) {
      onLoadedFunction(result, typeCode);
    }
  }

  /**
   * Get the options from the cache.
   *
   * @return {([]|{})} An array of options for a typeahead or a single object for a particular ID.  If we're still
   * waiting on the data from the back end, the options returned will include a single record with a message to wait.
   */
  getOptionsFromCache() {
    const id = this.getId();
    let returnValue = this.getOptionsFromCacheHelper(id, this.getCacheName());

    if (returnValue === null || returnValue === undefined) {
      returnValue = BaseObjectCache.getInitialOptions();
    }

    return returnValue;
  }

  getOptionsFromCacheHelper(id, cacheName, type = this.type) {
    let idToObjectOptions = JSON.parse(sessionStorage[cacheName])[type];
    let returnValue;
    if (idToObjectOptions) {
      returnValue = idToObjectOptions[id];
    } else {
      returnValue = null;
    }
    return returnValue;
  }

  setCacheOptions(options) {
    this.setCacheOptionsHelper(this.getCacheName(), options);
  }

  /**
   * This is just to help the setCacheOptions method with setting archived vs non-archived data.
   */
  setCacheOptionsHelper(cacheName, options, type = this.type, id = this.getId()) {
    let objectToIdToOptions = JSON.parse(sessionStorage[cacheName]);
    if (!objectToIdToOptions[type]) {
      objectToIdToOptions[type] = {};
    }
    objectToIdToOptions[type][id] = options;
    sessionStorage[cacheName] = JSON.stringify(objectToIdToOptions);
  }

  /**
   * Call this method to invalidate cache options so they're reloaded for the type passed into the constructor the next
   * time one of the load() * methods are called.
   */
  invalidateCacheOptions() {
    this.clearIdToOnLoadedFunctions();
    this.invalidateCacheHelper(this.getCacheName());
  }

  invalidateCacheHelper(cacheName, type = this.type) {
    const id = this.getId();
    if (sessionStorage[cacheName]) {
      let objectToIdToOptions = JSON.parse(sessionStorage[cacheName]);
      if (objectToIdToOptions[type]) {
        if (id) {
          delete objectToIdToOptions[type][id];
        } else {
          delete objectToIdToOptions[type];
        }
        sessionStorage[cacheName] = JSON.stringify(objectToIdToOptions);
      }
    }
  }
}
